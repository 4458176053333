:root {
  --border-width: 2px;
  --button-width: 6rem;
  --scrollbar-width: 5px;
  --menu-button-size: 32px;
  --menu-width: 400px;
  --gutter: 1rem;
  --spacing: 1rem;
  --menu-open-duration: 0.2s;
  --light: #ffffff;
  --light-gray: #ededed;
  --dark-gray: #bababa;
  --dark: #111111;
  --teal: #0c6457;
  --dark-teal: #074038;
  --red: #af0c0c;
}

@media (min-width: 960px) {
  :root {
    --gutter: 2rem;
    --button-width: 8.5rem;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  color: var(--dark);
  font-size: 1rem;
  font-weight: normal;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
  outline: none;
}

button {
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  border: none;
  background: none;
}

a:visited,
a:link,
a:active {
  color: var(--teal);
}

a:hover {
  color: var(--dark-teal);
}

h2 {
  margin: 0 0 calc(var(--spacing) / 2);
  font-size: 1.5rem;
  font-weight: lighter;
  width: 100%;
}

p {
  margin: 0 0 var(--spacing);
  width: 100%;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

ul,
ol {
  margin: 0;
  width: 100%;
  margin: 0 0 calc(var(--spacing) / 2);
  list-style-position: inside;
}

li {
  margin: 0 0 calc(var(--spacing) / 2);
}

table {
  font-size: 1.25rem;
  align-self: flex-start;
}

td {
  padding-bottom: 0.5rem;
}

@media (min-width: 960px) {
  td:not(:first-of-type) {
    padding-left: 2rem;
  }
}

@media (max-width: 959px) {
  td {
    display: block;
  }
}
